import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse, Dropdown } from "react-bootstrap";
import { Trans } from "react-i18next";
import localForage from "localforage";
import {
  GpsFixedRounded,
  InsertChartRounded,
  ViewListRounded,
} from "@material-ui/icons";

class Sidebar extends Component {
  state = {
    email: "",
    key: "",
  };
  static get restrictedOnlyDiversity() {
    return {
      auth: [
        "gabriel.kanno@faber-castel.com.br",
        "gabriel.kanno@faber-castell.com",
        "nayda.zago@faber-castell.com",
        // "durval.junior@faber-castell.com",
        "hevellen.almeida@faber-castell.com",
        "welidy.daniel@faber-castell.com",
        "bruno.fernandes@faber-castell.com",
        "lucas.lumini@faber-castell.com",
      ],
    };
  }
  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        console.log(i);
        if (i !== "email" || i !== "auth") {
          this.setState({ [i]: false });
        }
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    // this.setState({ isValid: true });
    // document.querySelector("#sidebar").classList.remove("active");
    // Object.keys(this.state).forEach((i) => {
    //   this.setState({ [i]: false });
    // });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
    const currentEmail = localStorage.getItem("email");
    this.setState({
      email: currentEmail,
    });
  }

  async componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="index.html">
            <img
              src={require("../../assets/images/logo-atento.png")}
              alt="logo"
              style={{
                objectFit: "contain",
                width: "200px",
                height: "200px",
              }}
            />
          </a>
          <a className="sidebar-brand brand-logo-mini" href="index.html">
            <img
              src={require("../../assets/images/triangle.ico")}
              alt="logo"
              style={{
                objectFit: "contain",
                width: "90%",
                height: "90%",
                marginLeft: "-10%",
              }}
            />
          </a>
        </div>
        <ul className="nav">
          <li className="nav-item nav-category">
            <span className="nav-link" style={{ color: "white" }}>
              <Trans>Navegação</Trans>
            </span>
          </li>

          <li
            className={
              this.isPathActive("/turnover") ||
              this.isPathActive("/headcount") ||
              this.isPathActive("/diversidade") ||
              this.isPathActive("/absenteismo") ||
              this.isPathActive("/horasextras") ||
              this.isPathActive("/bancohoras") ||
              this.isPathActive("/desligamentos")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.userPagesMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("userPagesMenuOpen")}
              data-toggle="collapse"
            >
              <span
                className="menu-icon"
                style={{ backgroundColor: "transparent" }}
              >
                <InsertChartRounded sx={{ fontSize: "20px" }} />
              </span>
              <span className="menu-title">
                <Trans>Dashboard</Trans>
              </span>
              <i className="menu-arrow" style={{ color: "white" }}></i>
            </div>
            {this.constructor.restrictedOnlyDiversity.auth.includes(
              localStorage.getItem("email")
            ) ? (
              <Collapse in={this.state.userPagesMenuOpen}>
                <div>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/diversidade")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/diversidade"
                      >
                        <Trans>Diversidade</Trans>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Collapse>
            ) : (
              <Collapse in={this.state.userPagesMenuOpen}>
                <div>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/turnover")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/turnover"
                      >
                        <Trans>%Turnover</Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/desligamentos")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/desligamentos"
                      >
                        <Trans>Desligamentos</Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/diversidade")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/diversidade"
                      >
                        <Trans>Diversidade</Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/absenteismo")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/absenteismo"
                      >
                        <Trans>Absenteísmo</Trans>
                      </Link>
                    </li>

                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/headcount")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/headcount"
                      >
                        <Trans>Headcount</Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/horasextras")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/horasextras"
                      >
                        <Trans>Horas Extras</Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/bancohoras")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/bancohoras"
                      >
                        <Trans>Banco de horas</Trans>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Collapse>
            )}
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  // componentDidMount() {
  //   this.onRouteChanged();
  //   // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
  //   const body = document.querySelector("body");
  //   document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
  //     el.addEventListener("mouseover", function () {
  //       if (body.classList.contains("sidebar-icon-only")) {
  //         el.classList.add("hover-open");
  //       }
  //     });
  //     el.addEventListener("mouseout", function () {
  //       if (body.classList.contains("sidebar-icon-only")) {
  //         el.classList.remove("hover-open");
  //       }
  //     });
  //   });
  // }
}

export default withRouter(Sidebar);
